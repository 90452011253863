@import "../../../colors.css";

.dropdown {
  position: relative;
}

.dropdown.open > .dropdownMenu {
  display: block;
}

.dropdownToggle {
  display: inline-block;
  padding: 9px 12px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;    
  cursor: pointer;    
  color: var(--black);
  border: 1px solid var(--light-gray1);
  border-radius: 4px;
  font-family: "AvenirNext-Medium";
  background-color: var(--white);
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 2px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: var(--white);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid var(--light-gray1);
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,.175); 
}

.rightAligned .dropdownMenu {
  right: auto;
  left: 0;
}

.rightAligned .dropdownToggle {
  margin-left: 0;
}

.btnContainer {
  margin-bottom: 10px;
  margin-right: 10px;
  text-align: right;
}