@media (min-width: 481px) {
  .sectionsList {
    column-count: 2;
  }
}

.sectionsList li {
  display: inherit;
}

.excerptInput label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
}