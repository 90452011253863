@import "../../colors.css";

.classDataWrapper {
  height: 100%;
  max-width: 1300px;
  padding: 20px 30px;
}

.mathClassDataHeader {
  margin: 10px 0px 40px 0px;
  display: flex;
  justify-content: space-between;
}

.autoAssignContainer {
  font-family: "AvenirNext-Medium";
  border: solid 1px var(--placeholder-gray);
  border-radius: 5px;
  width: fit-content;
  padding: 5px 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.autoAssignSwitch {
  margin-left: 20px;
}

@media print {
  .hideForPrint {
    display: none;
  }
}
