.studentViewContainer {
  font-family: 'WorkSans-Regular';
  max-width: 1300px;
  width: 100%;
  margin: auto;
}

.welcomeSection {
  display: flex;
  min-width: 320px;
  padding: 32px 16px;
  background-color: rgba(160, 168, 183, 0.16);
  flex-direction: column;
  margin-bottom: 64px;
}
.welcomeMessageContainer {
  display: flex;
  flex-direction: column;
  flex: 1 2;
}
.welcomeMessage {
  font-size: 32px;
  text-transform: uppercase;
  font-family: 'WorkSans-Black';
}
.welcomeInfoMessage {
  font-size: 24px;
  margin-top: 16px;
  font-family: 'WorkSans-SemiBold';
  letter-spacing: -0.022em;
  color: #656565;
}
.welcomeInfo {
  color: var(--blue);
}
.welcomeCardContainer,
.completeStateContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-top: 24px;
}
.doNext {
  font-size: 24px;
  font-family: 'WorkSans-Black';
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.welcomeCard {
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 0px 12px 0px #00000026;
  margin-top: 16px;
  cursor: pointer;
}
.welcomeCardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 240px;
  width: 100%;
  padding: 24px;
}
.welcomeCardWrapperWithPrereq {
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}
.welcomeCardInfo {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  margin-bottom: 24px;
}
.welcomeLevelSet {
  margin-top: 16px;
}
.assignmentName {
  font-family: 'WorkSans-Bold';
  font-size: 18px;
  margin-top: 16px;
}
.cardDueDate {
  margin-top: 16px;
}
.welcomeCardPreReq {
  border-radius: 0;
  margin: 0;
  box-shadow: none;
}

.zeroStateContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.zeroStateTextContainer {
  width: 400px;
  color: #3375ca;
}
.zeroStateTitle {
  font-family: 'WorkSans-ExtraBold';
  font-size: 48px;
  margin-bottom: 16px;
  line-height: 56px;
}
.zeroStateText {
  font-family: 'WorkSans-Medium';
  font-size: 24px;
  line-height: 32px;
}
.completeStateContainer {
  background-color: rgba(255, 255, 255, 0.2);
  align-items: center;
  justify-content: center;
  padding: 24px;
}
.completeStateTextContainer {
  color: #3375ca;
}
.completeStateTitle {
  font-family: 'WorkSans-ExtraBold';
  font-size: 24px;
  margin-bottom: 16px;
  line-height: 32px;
}
.completeStateText {
  font-family: 'WorkSans-Medium';
  font-size: 16px;
  line-height: 32px;
}
.mastery {
  display: flex;
  align-items: center;
  grid-area: 4 / 1 / 5 / 2;
}

@media screen and (min-width: 1024px) {
  .studentViewContainer {
    padding: 20px 30px;
  }
  .welcomeSection {
    flex-direction: row;
  }
  .welcomeMessageContainer {
    margin-right: 36px;
  }
  .welcomeCardInfo {
    margin-bottom: 0;
  }
  .welcomeCardContainer,
  .completeStateContainer {
    margin-top: 0;
  }
  .completeStateContainer {
    flex-direction: row;
  }
}
@media screen and (min-width: 800px) {
  .welcomeSection {
    padding: 32px;
  }
}
@media screen and (min-width: 600px) {
  .welcomeCardWrapper {
    flex-direction: row;
    padding: 36px 24px;
    align-items: flex-start;
  }
  .zeroStateContainer {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .mastery {
    grid-area: 1 / 5 / 2 / 6;
  }
}