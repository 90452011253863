@import "../../../colors.css";

.header {
  padding: 10px 40px;
  z-index: 1; /* so shadow appears over main */
  position: sticky;
  top: 0;
  flex-grow: 2;
  background: var(--white);
}

.headerWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1120px;
  z-index: 1; /* so shadow appears over main */
}

.mainTitle {
  color: var(--dark-blue);
}

.filters {
  display: flex;
  flex-wrap: wrap;
}
