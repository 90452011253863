.legendTooltip {
  margin-left: 15px;
}

.legendTooltipInner {
  font-size: 14px;
  padding: 0 10px;
  text-align: left;
}

.legendTooltipInner ul {
  padding-left: 20px;
}

.legendTooltipInner ul li {
  margin-bottom: 10px;
  line-height: 1.4;
}