@import "../../../colors.css";

.container {
  top: 0;
  padding: 0;
  max-width: 900px;
  width: auto;
  transform: translateX(-50%);
  margin-left: 0;
}

.alertInfo,
.alertWarning {
  font-size: 16px;
  line-height: 1.85;
  border-radius: 0;
  padding: 10px 15px;
  align-items: center;
  min-height: auto;
  margin-bottom: 0;
  font-family: 'AvenirNext-Medium';
  color: var(--white);
}

.alertInfo {
  background-color: var(--blue);
}

.alertWarning {
  background-color: var(--red);
}

.alertWarning button,
.alertInfo button {
  color: var(--white);
  opacity: 1;
  margin-left: 50px;
  font-size: 20px;
}
