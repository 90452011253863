@import "../../../colors.css";

.loadingContainer {
  color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  width: 100%;
}