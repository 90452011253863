
.recentAssignmentBtn::before {
  content: '(';
}

.recentAssignmentBtn::after {
  content: ')';
}

.recentAssignmentBtn::after,
.recentAssignmentBtn::before {
  color: var(--text-color, #000000);
}

.recentAssignmentBtn {
  white-space: normal !important;
  text-align: left;
}

.radioInput [class*='MuiRadio-root'] {
  align-self: flex-start;
}

.radioInput {
  margin-bottom: 5px;
}

.assignLayerAssignmentSettings {
  font-size: 16px !important;
}