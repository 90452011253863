@import "../../../../colors.css";

.locationButtonContainer {
  text-align: center;
}

.locationButton {
  font-size: 16px;
  color: var(--gray-blue);
  margin-right: 30px;
  border: none;
  background-color: var(--white);
  font-family: 'AvenirNext-Medium';
  cursor: pointer;
}

.locationButton div {
  font-size: 24px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  margin-bottom: 10px;
  border: 1px solid var(--light-gray2);
  border-radius: 50px;
}

.locationButton:hover div,
.locationButton.selected div {
  color: var(--orange);
  background-color: var(--light-gray2);
}

.locationButton:focus,
.locationButton:active {
  outline: none;
}