@import "./colors.css";

#chartjs-tooltip {
  position: absolute;
  padding: 10px;
  border-style: solid;
  transition: all .1s ease;
  pointer-events: none;
}
#chartjs-tooltip .title {
  font-weight: bold;
  white-space: nowrap;
}
#chartjs-tooltip .mastery {
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
}
#chartjs-tooltip .date,
#chartjs-tooltip .time {
  font-weight: bold;
  color: var(--gray);
}
#chartjs-tooltip .reset {
  font-weight: bold;
  color: var(--gray);
}
#chartjs-tooltip:before {
  background-color: var(--white);
  border: solid var(--blue);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  content: "";
  display: block;
  position: absolute;
}
#chartjs-tooltip.top.left {
  margin-top: 10px;
  margin-left: -20px;
}
#chartjs-tooltip.top.left:before {
  transform: rotate(-135deg);
  left: 10px;
  top: -10px;
}
#chartjs-tooltip.top.center {
  transform: translate(-50%, 10px);
}
#chartjs-tooltip.top.center:before {
  transform: translateX(-50%) rotate(-135deg);
  left: 50%;
  top: -10px;
}
#chartjs-tooltip.top.right {
  transform: translate(-100%, 0);
  margin-top: 10px;
  margin-left: 20px;
}
#chartjs-tooltip.top.right:before {
  transform: rotate(-135deg);
  right: 10px;
  top: -10px;
}
#chartjs-tooltip.center.left {
  transform: translate(0, -50%);
  margin-left: 10px;
}
#chartjs-tooltip.center.left:before {
  transform: translate(-20px, 50%) rotate(135deg);
  margin-top: 12px;
}
#chartjs-tooltip.center.right {
  transform: translate(-100%, -50%);
  margin-left: -10px;
}
#chartjs-tooltip.center.right:before {
  transform: translate(0, 50%) rotate(-45deg);
  margin-top: 12px;
  right: -10px;
}
#chartjs-tooltip.bottom.left {
  transform: translate(0, -100%);
  margin-top: -10px;
  margin-left: -20px;
}
#chartjs-tooltip.bottom.left:before {
  transform: rotate(45deg);
  left: 10px;
  bottom: -10px;
}
#chartjs-tooltip.bottom.center {
  transform: translate(-50%, -100%);
  margin-top: -10px;
}
#chartjs-tooltip.bottom.center:before {
  transform: translateX(-50%) rotate(45deg);
  left: 50%;
  bottom: -10px;
}
#chartjs-tooltip.bottom.right {
  transform: translate(-100%, -100%);
  margin-top: -10px;
  margin-left: 20px;
}
#chartjs-tooltip.bottom.right:before {
  transform: rotate(45deg);
  right: 10px;
  bottom: -10px;
}

@media print {
  canvas.chartjs-render-monitor {
    height: 100px !important;
    width: auto !important;
  }
}