@import "../../../colors.css";

.paging {
  text-align: center;
  font-family: 'AvenirNext-Bold';
  font-size: 24px;
  letter-spacing: -0.87px;
  color: var(--dark-blue);
  display: flex;
  align-items: center;
}

.numbers {
  width: 146px;
}

.input {
  color: var(--dark-blue);
  font-family: 'AvenirNext-Bold';
  border: none;
  font-size: 24px;
  width: 50px;
  text-align: right;
  background: var(--white);
}

.input:hover,
.input:focus {
  text-align: center;
  border: 1px solid var(--light-gray1);
  margin-right: 0px;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type=number] {
  -moz-appearance: textfield;
}