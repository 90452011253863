@import "../../colors.css";

.main {
  padding: 30px 40px;
}

.main ul {
  list-style: none;
  padding: 0;
}

.conceptContainer {
  margin-bottom: 40px;
  max-width: 900px;
  color: var(--dark-blue);
}

.conceptHeader {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 20px;
  outline: none;
}

/* https://a11yproject.com/posts/never-remove-css-outlines */
.conceptHeader:focus,
.conceptHeader:hover,
.conceptHeader.isExpanded {
  color: var(--orange);
}

.conceptExpandArrow {
  font-size: 18px;
  line-height: 0;
}

.conceptName {
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
  letter-spacing: 0.5px;
}

.conceptCountBubble {
  background: var(--orange);
  color: var(--white);
  padding: 5px 9px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.standardsList {
  display: none;
  transition: all 0.5s;
}

.standardsList.isExpanded {
  display: initial;
}

.standard {
  margin-bottom: 30px;
  margin-left: 40px;
}

.standardName {
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--dark-blue);
  line-height: 1.4;
  letter-spacing: 1.7px;
  font-weight: bold;
}

.subStandard {
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px;
  padding-left: 40px;
  margin-bottom: 20px;
  border: solid 1px var(--light-gray1);
  border-radius: 5px;
  box-shadow: 1px 1px 10px var(--light-gray3);
  cursor: pointer;
}

.subStandardLevelSet {
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px;
  padding-left: 40px;
  margin-bottom: 20px;
  cursor: pointer;
}

.standardLevelSetSeries {
  display: flex;
  border: solid 1px var(--light-gray1);
  border-radius: 5px;
  box-shadow: 1px 1px 10px var(--light-gray3);
}

.buttonContainerLevelSet {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.subStandard:hover {
  border-color: var(--orange);
}

.subStandardNameDescContainer {
  max-width: 600px;
}

.subStandardNameContainer {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  color: var(--dark-blue);
}

.subStandardName {
  display: inline;
  vertical-align: text-top;
}

.subStandardCode {
  margin: 5px 0px;
  display: flex;
  flex-wrap: wrap;
}

.subStandardDescription {
  color: var(--gray);
  line-height: 1.3;
}

.standardCode {
  font-size: 10px;
  background: var(--light-gray2);
  padding: 5px 10px;
  border-radius: 10px;
  margin: 0px 5px 5px 0px;
}

.redFavorite {
  color: var(--red);
}

.info {
  color: var(--medium-gray);
  margin-top: 10px;
  font-size: 14px;
}

.link {
  color: var(--blue);
  padding-left: 5px;
}

.listProblems {
  list-style: none;
  margin-left: 32px;
}

.unpublished {
  color: var(--red);
}