.tableContainer {
  margin-bottom: 64px;
}
.tableTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  color: var(--white);
  background-color: #36474f;
  font-family: 'WorkSans-Bold';
  padding: 8px 16px;
  margin-bottom: 8px;
}
.tableContentContainer {
  padding: 0 24px;
}
.tableTitle {
  font-size: 18px;
}
.tableTitleSort {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
.tableHeader,
.tableRow {
  display: grid;
  padding: 16px 24px;
  font-size: 14px;
}
.tableRowContainer {
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 0px 12px 0px #00000026;
  margin-bottom: 8px;
}

.tableHeader {
  display: none;
}
.tableRow {
  cursor: pointer;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 32px 32px 32px 32px;
  align-items: center;
}
.tableRowNoMastery {
  grid-template-rows: 32px 32px 32px;
}
.assignmentNameTile {
  display: flex;
  flex: 2 auto;
  font-family: 'WorkSans-Medium';
  font-size: 14px;
  grid-area: 2 / 1 / 3 / 2;
}
.completedCount {
  display: flex;
  flex: 1 auto;
  font-size: 14px;
  grid-area: 3 / 2 / 4 / 4;
}
.tableViewAllContainer {
  display: flex;
  justify-content: flex-end;
  color: #3375ca;
  text-transform: uppercase;
  font-family: 'WorkSans-Black';
  font-size: 14px;
}
.tableViewAllButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.tableRowDisabled {
  opacity: 0.5;
  cursor: default;
}

@media screen and (min-width: 768px) {
  .tableHeader {
    display: grid;
    font-family: 'WorkSans-Bold';
    font-size: 12px;
    text-transform: uppercase;
    color: #606060;
    padding: 16px 24px;
  }
  .tableHeader,
  .tableRow {
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
  }
  .tableRowNoMastery {
    grid-template-columns: 1fr 1.5fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .tableTitleContainer {
    margin: 0;
  }
  .tableContentContainer {
    padding: 0;
  }
  .assignmentNameTile {
    grid-area: 1 / 2 / 2 / 3;
  }
  .completedCount {
    grid-area: 1 / 4 / 2 / 5;
  }
}
@media screen and (max-width: 481px) {
  .completedCount {
    justify-self: end;
  }
}