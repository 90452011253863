.buttonGroup {
  box-sizing: border-box;
  display: flex;

  &.square {
    a,
    button {
      border-radius: 0;
      font-size: 16px;
      flex-shrink: 0;
      width: 40px;
      height: 50px;
      border-right: 1px solid #ececec;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media (min-width: 375px) {
        width: 50px;
        height: 50px;
      }

      @media (min-width: 1024px) {
        width: 80px;
        height: 80px;
      }
  
      svg {
        fill: #263237;
        stroke: #263237;
      }
      
      &.logo {
        svg {
          fill: #0077d1;
          stroke: none;
        }
      }

      svg + [class*="icon-"] {
        position: absolute;
        top: 10px;
        right: 0px;

        @media (min-width: 1024px) {
          top: 18px;
          right: 12px;
        }
      }

      &:hover, &:focus {
        background-color: #0077d1;

        div {
          color: white;
        }
  
        svg {
          fill: white;
          stroke: white;
        }

        &.logo {
          svg {
            stroke: none;
          }
        }

        [class*="icon-"]::before {
          color: #fff,
        }
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        position: absolute;
        bottom: 0;
      }
  
      &.disabled {
        opacity: 0.24;
        pointer-events: none;
      }
  
      &.selected {
        &::after {
          background-color: #0077d1;
        }
      }
  
      img {
        width: 25px;
        height: 25px;

        @media (min-width: 1024px) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &.round {
    padding: 0 10px;

    a,
    button {
      margin-right: 10px;
      width: 35px;
      height: 35px;
        
      @media (min-width: 375px) {
        width: 40px;
        height: 40px;
      }

      @media (min-width: 400px) {
        margin-right: 20px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    @media (max-width: 320px) {
      padding: 0 5px;
    }
  }

  &.orangeTheme {
    a,
    button {
      &:hover, &:focus {
        background-color: #e89d3d;
      }
      &.selected {
        &::after {
          background-color: #e89d3d;
        }
      }
    }
  }
}

