.left,
.right {
  display: flex;
  align-items: center;
}

.left {
  flex: 1 1;
}

.right {
  margin-right: 24px;
}