@import "../../../colors.css";

.checklist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.checklist > li:nth-of-type(odd) {
  background-color: var(--light-gray);
}

.checklist > li {
  padding: 10px 12px;
}
