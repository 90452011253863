.description p,
.description {
  color: var(--label-text-color);
  font-size: 0.875rem;
}

.publisher {
  color: var(--label-text-color);
  text-transform: uppercase;
}

.noteIcon {
  vertical-align: text-top;
}

div[class*="MuiTooltip-tooltip"] {
  margin: 3px 0px !important;
}

.standard {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
}

.studentLinkContainer {
  background-color: var(--divider-color);
}

.coverArt img {
  width: 200px;
  max-width: 25vw;
}

.coverArt {
  margin-right: 30px;
  margin-bottom: 20px;
}

.baseContentDetail {
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
  margin-bottom: 30px;
}

.baseContentDetail h2 {
  font-size: 1.5rem;
  line-height: 1.2;
}

@media only screen and (max-width: 480px) {
  .coverArt img {
    width: 100%;
    max-width: 100%;
  }

  .baseContentDetail {
    flex-direction: column;
  }
}