@import "../../../colors.css";

.filterContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.legendContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.masteryCell {
  text-align: center;
}

.classCell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pieChartContainer {
  margin-top: 10px;
  width: 189px; /* table cell width is 220, so manually setting pie chart width which has its own padding */
}

@media print {
  .hideForPrint {
    display: none;
  }

  .tableContainer th {
    padding: 0 !important;
  }

  .tableContainer th,
  .tableContainer td {
    max-width: 80px !important;
  }
}

.studentNameLink:hover {
  color: var(--blue);
  cursor: pointer;
}

.expandingRow:hover {
  cursor: pointer;
}

.expandIcon {
  vertical-align: text-top;
  font-size: 18px;
}