@import "../../../colors.css";

.standardName {
  font-family: 'AvenirNext-Medium';
  font-size: 16px;
}

.noProblemsCompleted {
  color: var(--medium-gray);
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.skillContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.yAxisContainer {
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: space-around;
  margin-right: -14px;
  margin-top: -30px;
  text-align: right;
}

.yAxisLabel {
  color: var(--medium-gray);
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: 800;
}

.interventionSource {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
}

.btnReset {
  padding: 0;
  font-size: 11px;
  color: var(--orange);
  font-family: "AvenirNext-Medium";
}

@media print {
  .hideForPrint {
    display: none;
  }
}

.tableCell {
  border-bottom-width: 5px;
  height: 175px;
}