.snackbar {
  top: 30px !important;
  z-index: 1010 !important;
  &:focus{
    outline: 1px solid var(--al-blue);
    outline-offset: 2px
  }
}

.snackbarContent {
  background-color: #0077d1;
  padding: 10px 0;
  border-radius: 15px;
  
  @media (min-width: 500px) {
    padding: 20px;
  }
}