@import "../../../colors.css";

.button {
  font-size: 58px;
  padding: 0px 10px;
  line-height: 0;
}

.button.intervention {
  color: var(--intervention-purple);
}

.button.step {
  color: var(--white);
}