.wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
  margin-left: 72px;
  margin-right: 72px;
  flex-wrap: wrap;
  max-width: 100vw;
  align-items: center;
  margin-bottom: 48px;
}

.contentWrapper {
  margin-bottom: 40px;
  max-width: 80vw;
  color: var(--dark-blue);
  padding: 30px 40px;
}

.headerWrapper {    
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.headerLayout {
  padding: 10px !important;
}