.header {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-template-areas: 'coreButtons actionButtons' 'navItems navItems';
  grid-template-columns: 1fr 1fr;
  font-family: 'WorkSans-Medium';
  position: relative;
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);

  @media (min-width: 1024px) {
    grid-template-areas: none;
    grid-template-columns: auto minmax(150px, 1fr) auto;
    height: 80px;
  }
}

.navItems {
  height: 100%;
  grid-area: navItems;
  width: 100%;

  @media (min-width: 1024px) {
    grid-area: auto;
  }
}

.coreButtons {
  grid-area: coreButtons;

  @media (min-width: 1024px) {
    grid-area: auto;
  }
}

.actionButtons {
  grid-area: actionButtons;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 1024px) {
    grid-area: auto;
    margin-right: 10px;
  }
}
