:root {
  /* colors */
  --white: #fff;
  --off-white: #fafafa;
  --light-gray: #f2f2f2;
  --light-gray1: #ccc;
  --light-gray2: #eee;
  --light-gray3: #e8e8e8;
  --light-gray4: #dedede;
  --placeholder-gray: #cecfcf;
  --medium-gray: #a1a1a1;
  --gray: #888;
  --black: #000;
  --light-gray-blue: #b0bec5;
  --gray-blue: #455a64;
  --blue: #0077d1;
  --dark-blue: #134d5f;
  --orange: #e89d3d;
  --red: #f50057;
  --red_800: #c10606;
  --green: #0eb9a1;
  --intervention-purple: #786482;
  --gray_verylight: #E8E8E8;
  --al-blue: #0077D1;

  /* fonts */
  --font-reg: "WorkSans-Regular";
  --font-light: "WorkSans-Light";
  --font-med: "WorkSans-Medium";
  --font-semi-bold: "WorkSans-SemiBold";
  --font-bold: "WorkSans-Bold";
  --font-avenir-med: "AvenirNext-Medium";
}