.modal p,
.modal a {
  font-family: var(--font-med) !important;
}

.lightText {
  color: var(--label-text-color);
  font-family: var(--font-reg);
}

.totalLicenses {
  margin-left: 10px;
}

.info {
  color: rgba(0, 0, 0, 0.54);
}

.txtQuantity {
  max-width: 74px;
  height: 30px;
  text-align: center;
}

.greyInput {
  background: var(--bluegrey_50);
  border: 0;
  padding: 8px 10px;
  border-radius: 4px;
  font-family: "WorkSans-SemiBold";
  font-size: 16px;
  outline: none;
  align-self: stretch;
  margin-right: 10px;
  width: auto !important;
}

.paymentFormInput {
  height: 35px;
  border: 1px solid var(--bluegrey_600);
  margin-bottom: 0;
}

.paymentFormInput:focus {
  outline: 2px solid var(--al-blue);
  outline-offset: 2px;
}

.paymentFormInput:disabled {
  padding-left: 10px !important;
  background-color: var(--bluegrey_50) !important;
}

.purchaseForm input {
  width: auto;
}

.headCountPopup {
  padding: 8px 9px;
}

.headCountTable {
  text-align: center;
}

.headCountTable td {
  max-width: 250px;
}

.headCountTable td:first-child {
  text-align: left;
}

.headCountTable th {
  padding-right: 10px;
}

.paymentColumnOne {
  padding-right: 1em;
}

.monthInput {
  max-width: 20%;
}

.yearInput {
  max-width: 39%;
}

.alignSelfStart {
  align-self: flex-start;
}

.disabledFeatures {
  ul {
    margin-left: 20px;
    list-style: disc;
  }
}

.priceItems {
  display: flex;
  flex-direction: column;
}

.priceContainers {
  display: flex;
  flex-direction: row;
}

.priceFirstColumn {
  text-align: end;
}

.priceColumn {
  height: 2em;
}

.priceColumn:last-child{
  font-weight: bold;
}

.moneyGreen {
  color: var(--money-green);
}

.strikeThrough {
  text-decoration: line-through;
}
