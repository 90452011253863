@import "../../../colors.css";

.bar {
  display: none;
  border: solid 3px var(--orange);
  color: var(--dark-blue);
  font-size: 24px;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 40px;
  transition: ease-in 0.4s;
  text-align: right;
}

.bar.correct {
  border-color: var(--green);
}

.bar.incorrect {
  border-color: var(--red);
}

.message {
  margin-top: 0;
  text-align: left;
  line-height: 1.3;
}

.message img {
  max-width: 300px;
  margin-left: 20px;
}