@import "../../../colors.css";

.nameContainer {
  cursor: pointer;
}

.viewPastAssessmentsBtn {
  padding: 0;
  position: absolute;
  margin-left: 10px;
}

.cellContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-align: center;
}

.timeago {
  color: var(--medium-gray);
  font-family: "AvenirNext-Regular";
  text-transform: uppercase;
  font-size: 12px;
}

.timeTakenLocation {
  color: var(--medium-gray);
  font-family: "AvenirNext-Regular";
  font-size: 12px;
}

.helperText {
  color: var(--medium-gray);
  font-size: 12px;
  line-height: 16px;
}

.helperText span {
  font-size: 14px;
  color: var(--black);
}

.pastAssessmentsList {
  list-style-type: none;
  padding: 0;
}

.pastAssessmentsList li {
  margin-bottom: 20px;
}

.pastAssessmentsList li > div {
  margin-bottom: 3px;
}

.pastAssessmentsList .timeago {
  margin-left: 0;
  color: var(--dark-blue);
  font-size: 16px;
  font-family: "AvenirNext-Bold";
}

.pastAssessmentsList .helperText {
  font-size: 14px;
}

.tableHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.printButton {
  margin-left: auto;
}