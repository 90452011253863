.checkbox {
  display: flex !important;
  margin-top: 3px;
}

.checkbox [class*='MuiCheckbox-root'] {
  align-self: flex-start;
}

.previewOnly {
  pointer-events: none;
}

.subHeading {
  margin-left: 30px;
}

.writingCoachHeading {
  font-size: 1rem;
  padding: 0 !important;
}

@media screen and (max-width: 480px) {
  .checkbox {
    margin-top: 6px;
  }
}

.peerResponsesRadioGroup {
  margin-left: 30px;
  margin-top: -8px;
}

.infoIcon {
  padding: 0 !important;
}

.infoIcon svg {
  fill: var(--al-blue);
  width: 20px;
  height: 20px;
  vertical-align: bottom;
}

.infoIcon:focus {
  border-radius: 50%;
  outline-offset: 1px;
  outline-color: var(--al-blue);
}

.transparentButton {
  background: transparent;
  border: none;
  padding: 0 0 0 10px;
}

.transparentButton svg {
  width: 14px;
}