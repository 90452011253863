@import "../../../../colors.css";

.nameContainer {
  margin: 17px 20px 22px 20px;
}

.problemName {
  font-family: 'AvenirNext-Medium';
  font-size: 20px;
  font-weight: bold;
  color: var(--dark-blue);
}

.problemName.intervention {
  color: var(--intervention-purple);
}

.problemName.light {
  color: var(--white);
}

.standardName {
  font-family: 'AvenirNext-Bold';
  font-size: 28px;
  color: var(--dark-blue);
}

.standardName.intervention {
  color: var(--intervention-purple);
}

.standardName.light {
  color: var(--white);
}

.standardCode {
  font-size: 14px;
}