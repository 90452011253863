.dueDate {
  font-family: 'WorkSans-Regular';
  font-size: 14px;
  display: flex;
  align-items: center;
  grid-area: 3 / 1 / 4 / 2;
}
.dueDateText {
  margin-left: 14px;
}
.pastDue {
  color: #ff0000;
}

@media screen and (min-width: 768px) {
  .dueDate {
    grid-area: 1 / 3 / 2 / 4;
  }
}