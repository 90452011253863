.className {
  text-transform: uppercase;
  opacity: 0.62;
}

.assignmentName {
  opacity: 0.65;
  font-family: var(--font-semi-bold);
}

.url {
  opacity: 0.65;
}

.teacherActionsLink {
  display: block;
  margin: 15px 0;
  font-family: var(--font-med) !important
}