.appDrawer {
  width: 280px;
}

.appDrawerHeader {
  align-items: center;
  background-color: #263237;
  border-bottom: 1px solid #5a676d;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 170px 1fr;
  justify-items: end;
  min-width: 280px;
  padding: 10px;
  position: relative;
}

.appDrawerHeader.light {
  background-color: #edeff1;
  border-bottom: 0;
}

.logo {
  max-width: 100%;
  width: 100%;
  background: transparent;
  border: none;
}

.logo img {
  display: block;
  max-height: 45px;
}

.skipNavigationBtn {
  background-color: #707070;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  position: fixed;
  top: -100px;
  z-index: 1;
}

.skipNavigationBtn:focus {
  top: auto;
  bottom: 10px;
  left: 10px;
  color: #fff;
  outline: 2px solid #0077d1;
}

.menuListWrapper {
  position: relative;
  bottom: 0;
  background-color: #263237;
}

.menuListWrapper.light {
  background-color: #edeff1;
}

.scrollThumb {
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0 !important;
  border-radius: 10px;
}

.light .scrollThumb {
  background-color: rgba(0, 0, 0, 0.7);
}

.menuListWrapper:hover .scrollThumb {
  opacity: 1 !important;
}

.fixedSection {
  border-top: 1px solid #5a676d;
}