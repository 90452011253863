@import "../../colors.css";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: solid 1px var(--light-gray1);
  z-index: 11; /* beat out largest z-index from Learnosity css */
  position: sticky;
  top: 0;
  background: var(--white);
}

.footer {
  border-top: solid 1px var(--light-gray1);
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  max-width: 1024px;
  margin: 22px auto;
}

/* TODO: Do on phones only
.header { 
  flex-direction: column;
}
*/
