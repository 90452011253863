@import "./colors.css";

.rdrInputRanges {
  display: none;
}

.rdrMonthAndYearPickers {
  display: none;
}

.rdrMonthAndYearWrapper {
  padding-top: 0;
  height: 35px;
}

.rdrMonth {
  padding: 0 5px 5px 0;
  width: 240px;
}

.rdrDayNumber {
  top: 0;
  bottom: 0;
}

.rdrDefinedRangesWrapper {
  width: 135px;
}

.rdrDay {
  height: 2.5em;
  line-height: 2.5em;
}

.rdrDateDisplay {
  margin: 0 5px;
}

.rdrDateDisplayWrapper {
  background-color: var(--white);
}

.rdrDateDisplayItem {
  border: 1px solid var(--light-gray1);
  border-radius: 4px;
  font-size: 11px;
  vertical-align: middle;
  margin: 0;
  padding: 0 6px;
}

.rdrStaticRangeLabel {
  font-family: 'AvenirNext-Medium';
}