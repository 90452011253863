@import "../../../../colors.css";

.hints {
  min-width: 700px;
  max-width: 800px;
  margin-top: 20px;
}

.hints img {
  max-width: 680px;
}

.textArea {
  padding: 10px 15px;
  font-size: 16px;
  font-family: 'AvenirNext-Medium';
  border: 0;
  margin-left: 2px;
  width: calc(100% - 50px);
  border: solid 1px var(--light-gray1);
  border-radius: 5px;
  box-shadow: 1px 1px 10px var(--light-gray3);
}

.textArea::placeholder {
  font-family: 'AvenirNext-Bold';
  text-transform: uppercase;
  font-size: 12px;
  color: var(--placeholder-gray);
  letter-spacing: 0.5px;
}