.expertSelect {
  margin-top: 8px !important;
}

.modalTitle > div {
  padding-bottom: 16px;
}

button[class*='MuiIconButton-root'] {
  outline: none;
}

button:focus {
  outline: 2px solid var(--al-blue);
}

.headerMessage {
  font-size: 14px;
  color: #c10606;
  font-family: 'WorkSans-SemiBold';
  margin: 5px 0 0 0;
}