html,
body {
  margin: 0;
  height: 100%;
  font-family: 'AvenirNext-Medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: 'AvenirNext-Regular';
  src: url('./fonts/Avenir-Regular.woff') format('woff');
}

@font-face {
  font-family: 'AvenirNext-Medium';
  src: url('./fonts/Avenir-Medium.woff') format('woff');
}

@font-face {
  font-family: 'AvenirNext-Demi';
  src: url('./fonts/Avenir-Demi.woff') format('woff');
}

@font-face {
  font-family: 'AvenirNext-Bold';
  src: url('./fonts/Avenir-Bold.woff') format('woff');
}

@font-face {
  font-family: 'WorkSans-Regular';
  src: url("./fonts/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'WorkSans-Medium';
  src: url("./fonts/WorkSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url("./fonts/WorkSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'WorkSans-Bold';
  src: url("./fonts/WorkSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'WorkSans-ExtraBold';
  src: url("./fonts/WorkSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: 'WorkSans-Black';
  src: url("./fonts/WorkSans-Black.ttf") format("truetype");
}