@import "../colors.css";

.reactTable table {
  font-size: 16px !important;
}

.reactTable th {
  background-color: var(--off-white) !important;
  justify-content: center;
}

.reactTable td {
  overflow-wrap: break-word;
}