@import "../../../colors.css";

.form {
  margin-right: 20px;
  display: flex;
  border: solid 1px var(--light-gray1);
  border-radius: 5px;
  box-shadow: 1px 1px 10px var(--light-gray3);
  width: 250px;
  justify-content: space-between;
}

.txtSearch {
  padding: 10px 15px;
  font-size: 16px;
  font-family: 'AvenirNext-Medium';
  border: 0;
  margin-left: 2px;
  width: 100%;
}

.txtSearch::placeholder {
  font-family: 'AvenirNext-Bold';
  text-transform: uppercase;
  font-size: 12px;
  color: var(--placeholder-gray);
  letter-spacing: 0.5px;
}

.btnSearch {
  padding: 5px 15px;
  font-size: 15px;
  color: var(--medium-gray);
}

.favorite {
  padding: 5px 15px;
  font-size: 25px;
  color: var(--dark-blue);
  opacity: 0.38;
}

.redFavorite {
  color: var(--red);
  opacity: 1;
}

.blueFavorite:hover {
  color: var(--red);
  opacity: 1;
}
