@import "../../../../colors.css";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 230px;
  margin: 0px 30px;
  font-family: 'AvenirNext-Bold';
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--gray-blue);
  letter-spacing: 1.28px;
}

.top.intervention {
  color: var(--intervention-purple);
}

.number {
  font-size: 24px;
  letter-spacing: -0.87px;
}

.bar {
  background-color: #455a6438;
  border-radius: 5px;
  height: 10px;
}

.progress {
  background-color: var(--orange);
  border-radius: 5px;
  height: 100%;
  transition: all .5s;
}