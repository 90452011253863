@import "../../../../colors.css";

.container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  position: relative;
  margin-top: 20px;
}

.summary {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px var(--light-gray1);
  position: relative;
  z-index: 9999
}

.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.attemptLabel {
  margin-left: 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--medium-gray);
}

.stepName {
  display: block;
  font-size: 12px;
  color: var(--medium-gray);
}

.reset {
  font-size: 12px;
  color: var(--medium-gray);
}