.progressContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 260px;
}
.progressMastery,
.progressCompleted {
  display: flex;
  flex-direction: column;
  margin-right: 12px;
}
.progressTitle {
  font-family: 'WorkSans-Bold';
  font-size: 14px;
  color: #606060;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.progressCompletedCount {
  font-family: 'WorkSans-SemiBold';
  font-size: 18px;
}