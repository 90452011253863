@import "../../../colors.css";

@media (min-width: 1024px) {
  .tools {
    position: fixed;
    left: 10px;
  }

  .tools > div {
    flex-direction: column;
  }

  .tools > div > div {
    height: 1px !important;
    width: 100%;
  }
}

.graphButton {
  text-transform: initial;
  background: var(--light-gray2);
  height: 38px;
  padding: 5px 10px;
  color: var(--black);
  font-family: 'AvenirNext-Medium';
  font-size: 14px;
}

.formulaSheetButton {
  background: var(--light-gray2);
  height: 38px;
}

.iframe {
  border: 0;
  width: 100%;
  height: 500px;
}
