@import "../../../colors.css";

.button {
  border: 0;
  color: var(--dark-blue);
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 11px 28px;
  text-align: center;
  box-sizing: border-box;
  background-color: transparent;
  text-transform: uppercase;
  line-height: 1;
  white-space: nowrap;
  font-family: 'AvenirNext-Bold';
}

.smallIcon svg {
  font-size: 18px;
}

.largeIcon svg {
  font-size: 24px;
}

.button:disabled {
  opacity: 0.38;
  cursor: initial;
}

.light {
  color: var(--white);
}

.dark {
  background-color: var(--gray-blue);
  color: var(--white);
}

.emptyOrange {
  border: solid 2px var(--orange);
  border-radius: 27.5px;
  color: var(--orange);
  margin-left: 20px;
}

.filledOrange {
  background-color: var(--orange);
  border: solid 2px var(--orange);
  border-radius: 27.5px;
  color: var(--white);
  margin-left: 20px;
}

.small {
  font-size: 12px;
  padding: 11px 10px;
  border-radius: 5px;
}

.textTransformNone {
  text-transform: none;
}