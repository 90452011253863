.wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.overlay {
  background: rgba(0, 0, 0, var(--opacity_secondary_dark));
  height: 100vh;
  justify-content: center;
}

.fullScreen {
  position: fixed;
  z-index: 1350; /* above the Mui-Modals but not above the alert */
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}