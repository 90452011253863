.divider {
  color: var(--text-color);
  width: 100%;
  overflow: hidden;
  text-align: center;
  line-height: 1.2em;
  clear: both;
  border-bottom: initial !important;
  margin: 15px 0;
}

.divider::before,
.divider::after {
  content: "";
  vertical-align: top;
  display: inline-block;
  width: 50%;
  height: 0.65em;
  border-bottom: 1px solid var(--divider-color, rgba(0, 0, 0, 0.12));
  margin: 0 2% 0 -55%;
}

.divider::after {
  margin: 0 -55% 0 2%;
}

button.classImportButton {
  background-color: #fff;
  border-color: #0077D1;
  border-radius: 4px;
  color: #0077D1;
  margin: 10px 0 10px 0;
  width: 100%;
}

.classImportButton:hover,
.classImportButton:focus {
  color: white !important;
}

.classImportButton img {
  width: 30px;
  margin-right: 10px;
  vertical-align: middle;
}
