@import "../../../../colors.css";

.lrn {
  font-family: inherit;
  color: var(--dark-blue);
}

.lrn_stimulus .lrn_stimulus_content {
  line-height: 1.6;
}

main .lrn_response_wrapper {
  padding: 20px;
}

.lrn_classification .lrn_response_wrapper,
.lrn_association .lrn_response_wrapper,
.lrn_simpleshading .lrn_response_wrapper {
  border: none;
}

.lrn_widget .lrn_valid,
.lrn_widget .lrn_selected {
  background-color: var(--light-gray1);
}

.lrn .lrn_possibilityList,
.lrn .lrn_possibilityListContainer {
  z-index: inherit;
}

.lrn.lrn-author .lrn-author-item-nav {
  display: none;
}

.ReactModal__Content .lrn_graphplotting {
  display: flex;
}

.hintEditor .lrn-author-item-content-wrapper .lrn-qe-toolbar,
.hintEditor .lrn-author-item-content-wrapper .lrn-qe-edit-form .lrn-qe-more-options,
.hintEditor .lrn-qe .lrn-qe-toolbar .lrn-qe-toolbar-left.lrn-qe-sect-heading,
.hintEditor .lrn-author-item-content-wrapper .lrn-qe-edit-form > div:not([data-lrn-qe-input-path='stimulus']) {
  display: none;
}

.hintEditor .lrn-author,
.hintEditor .learnosity-question-editor,
.cke_source {
  min-height: 350px;
}

.hintEditor .lrn-author-item-content-wrapper {
  padding: 0;
}

.lrn_cke_dialog_math,
.cke_dialog_container,
.cke_menu_panel {
  z-index: 1500 !important;
}