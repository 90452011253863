@import "../../../colors.css";

.interventionContainer {
  border-top: solid 1px var(--light-gray4);
  padding-top: 15px;
  display: flex;
  width: 100%;
  text-align: left;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.interventionContainer label {
  width: 30px;
}

.nameContainer {
  width: 100%;
  font-family: 'AvenirNext-Medium';
  color: initial;
}

.name {
  background: none;
  border: 0;
  font-family: 'AvenirNext-Medium';
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  text-align: left;
}

.secondaryInfo {
  color: var(--medium-gray);
  margin-top: 5px;
  font-size: 14px;
}

.externalLink {
  margin-left: 5px;
  color: var(--blue);
}
