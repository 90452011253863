@import "../../../../colors.css";

.toolbar {
  border-radius: 25px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  width: 70px;
}

@media screen and (max-width: 1024px) {
  .toolbar {
    width: 280px;
  }
}

.divider {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.25);
}

.toolbar button {
  color: var(--orange);
}

/* https://stackoverflow.com/questions/24626908/how-to-get-rid-of-extra-space-below-svg-in-div-element */
.toolbar svg {
  display: block;
}

.toolbar div,
.toolbar span {
  margin: 0;
  height: 100%;
}

.toolbar div button > span {
  display: none;
}

.toolbar > span {
  height: 70px;
  width: 70px;
  text-align: center;
}