@import "../../../colors.css";

.scorecardsContainer {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px var(--light-gray3);
  margin-right: 10px;
  margin-bottom: 10px;
}

.scorecard {
  min-width: 100px;
  border-right: solid 1px var(--light-gray3);
  padding: 15px 15px 10px 10px;
  text-align: right;
}

.scorecard:last-child {
  border-right: 0;
}

.scorecardCaption {
  font-family: 'AvenirNext-Medium';
  color: var(--medium-gray);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  padding-left: 4px;
  padding-right: 3px;
}

.scorecardNum {
  font-family: 'AvenirNext-Bold';
  font-size: 20px;
  margin-top: 20px;
  color: var(--blue);
}

@media print {
  .scorecard {
    padding: 5px;
    min-width: 80px;
  }

  .scorecardNum {
    font-size: 14px;
  }

  .scorecardCaption {
    letter-spacing: 1;
    padding: 0;
  }
}