.primaryTable {
  font-family: var(--font-med);
  font-size: 14px;
  width: 100%;
}

.primaryTable th {
  border-top: solid 2px var(--bluegrey_200);
  border-bottom: solid 2px var(--bluegrey_200);
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0 16px 0 5px;
  font-family: var(--font-semi-bold);
  position: relative;
}

.primaryTable th,
.primaryTable td {
  overflow: hidden;
  background-color: white;
  display: flex !important;
  align-items: center;
}

.primaryTable td {
  border-bottom: 1px solid var(--gray_verylight);
  padding-left: 10px;
}

.primaryTable .fixedRow td {
  font-family: var(--font-bold);
  background-color: var(--gray_extremelight);
  border-bottom: 1px solid var(--bluegrey_200);
}

.primaryTable tr:hover > td {
  background-color: var(--gray_extremelight);
}

.primaryTable.scrolling .fixedColumn {
  box-shadow: 2px 0px 5px var(--light-gray1);
}

.secondaryTable {
  font-family: var(--font-reg);
  font-size: 14px;
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
}

.secondaryTable th {
  color: #253945;
  background-color: var(--off-white);
  padding: 10px 0;
  border-right: solid 1px var(--gray_verylight);
  border-top: solid 1px var(--gray_verylight);
  border-bottom: solid 1px var(--gray_verylight);
  display: flex;
  align-items: center;
  position: relative;
}

.secondaryTable th,
.secondaryTable td {
  padding: 8px 16px;
  background-color: white;
}

.secondaryTable th:first-child {
  border-left: solid 1px var(--gray_verylight);
}

.secondaryTable .striped tr:nth-of-type(odd) td {
  background-color: #e5f4ff;
}

.secondaryTable .striped tr:nth-of-type(even) td {
  background-color: white;
}

.secondaryTable td.gridLines {
  border-right: solid 1px var(--gray_verylight);
  border-bottom: solid 1px var(--gray_verylight);
}

.secondaryTable td.gridLines:first-child  {
  border-left: solid 1px var(--gray_verylight);
}


.secondaryTable,
.primaryTable {
  max-height: inherit;
}

.secondaryTable .fixedColumn,
.primaryTable .fixedColumn {
  position: sticky;
  left: 0;
  z-index: 1;
}

.sortArrow {
  border: 2px solid var(--al-blue);
  width: 8px;
  height: 8px;
  position: absolute;
  display: block;
  left: calc(100% - 17px);
  border-top: none;
  border-left: none;
  top: calc(50% - 3px);
  transform: rotate(45deg);
}

.arrowUp {
  transform: rotate(-135deg);
}

.arrowDown {
  transform: rotate(45deg);
}

.highlight {
  color: var(--al-blue) !important;
}

thead.fixedHeader {
  position: sticky;
  top: 0;
  z-index: 2;
}

.tableWrapper {
  overflow: auto;
  position: relative;
  max-height: inherit;
}

.tableWrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.tableWrapper::-webkit-scrollbar-track {
  background: var(--gray_extremelight);
}

.tableWrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.columnHeader {
  display: flex;
  align-items: center;
}

@media(max-width: 640px) {
  .primaryTable .fixedColumn {
    position: static;
  }
}