@import "../../../colors.css";

.container {
  font-size: 16px;
  border: 1px solid var(--medium-gray);
  margin: 10px 0;
  padding: 20px;
}

.info {
  margin-bottom: 20px;
}

.dateContainer {
  display: flex;
  margin-bottom: 10px;
}

.label {
  margin-top: 8px;
  margin-right: 10px;
  font-family: 'AvenirNext-Demi';
  font-size: 16px;
  width: 80px;
}

.textField {
  margin: 0 !important;
}

.textField input {
  padding: 8px;
  width: 185px;
  font-family: 'AvenirNext-Regular';
}

.textField input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  position: absolute;
  padding-left: 180px;
  left: -25px;
  height: 30px;
  opacity: 0;
}

.optional {
  margin-left: 10px;
  color: var(--medium-gray);
  padding-top: 7px;
}

.note {
  color: var(--gray);
  margin-top: 15px;
}


.levelSetContainer {
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid var(--gray);
}

.levelSetCheckbox {
  margin-top: -12px;
}