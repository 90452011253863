.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
}

@media print {
  .hideForPrint {
    display: none;
  }

  .tableContainer th {
    padding: 0 !important;
  }

  .tableContainer th,
  .tableContainer td {
    max-width: 80px !important;
  }
}