.main {
  max-width: 1200px;
  padding: 30px 40px;
}

.title {
  margin-bottom: 30px;
}

.tooltip {
  font-size: 14px;
  padding: 0 20px;
  text-align: left;
}

.tooltip ul {
  padding-left: 10px;
}

.tooltip ul li {
  margin-bottom: 10px;
  line-height: 1.4;
}

.tableContainer {
  position: relative;
}

.loadingOnTable {
  background: rgba(18, 68, 84, 0.07);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
