@import "../../../../colors.css";

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.syncCheckboxContainer {
  margin-left: 15px;
  font-size: 14px;
}

.selectedStudentCheckmark {
  position: absolute;
  right: 10px;
  margin-top: 4px;
  font-size: 12px;
}

.studentOption {
  background-color: var(--white) !important;
  color: var(--black) !important;
}

.studentOption:hover {
  background-color: var(--light-gray4) !important;
  cursor: pointer !important;
}

.studentOption label:hover {
  cursor: pointer !important;
}

.levelSetContainer {
  border-bottom: 1px solid var(--gray);
}

.dateContainer {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.label {
  margin-right: 10px;
  font-family: 'AvenirNext-Demi';
  font-size: 16px;
  width: 80px;
}

.dateListContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.nameContainer {
  display: flex;
  flex-direction: row;
}

.fullWidth {
  display: flex;
  justify-content: space-between;
}

.alreadyAssignedClassesWrapper {
  margin: 12px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.gradeMismatchModal [class*=okButton] {
  color: var(--red_800) !important;
}