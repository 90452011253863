.controlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -20px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.btnViewBy {
  width: 235px;
}

.studentNameCellContainer {
  cursor: pointer;
}

.problemSetNameCellContainer {
  width: 168px;
}

.number {
  cursor: pointer;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.autoAssignToggle {
  display: inline-block;
  margin-left: 20px;
}

@media print {
  .hideForPrint {
    display: none;
  }
}

.expandingRow:hover {
  cursor: pointer;
}

.suggestedCol {
  vertical-align: top;
}