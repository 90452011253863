@import "../../../../colors.css";

.header {
  background-color: var(--dark-blue);
}

.header.intervention {
  background-color: var(--intervention-purple);
}

.stepPillsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px 30px;
}

.stepPillsContainer button {
  padding: 12px 17px;
  border: solid 2px var(--white);
  border-radius: 50px;
  font-size: 24px;
}

.stepPillsContainer button.selected {
  background-color: var(--white);
  color: var(--gray-blue);
}

.stepPillsContainer button.answered {
  padding: 13px 15px;
  border-color: var(--orange);
  color: var(--orange);
  font-size: 20px;
}

.stepPillsContainer button.selected.answered {
  background-color: var(--orange);
  color: var(--white);
}

.line {
  height: 2px;
  width: 20px;
  background-color: #FFFFFF61;
}

.line.orange {
  background-color: var(--orange);
}