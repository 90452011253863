.font-xs {
  font-size: 0.75rem !important;   /* 12px */
}

.font-sm {
  font-size: 0.875rem !important;   /* 14px */
}

.font-md {
  font-size: 1rem !important;   /* 16px */
}

.font-lg {
  font-size: 1.125rem !important;   /* 18px */
}