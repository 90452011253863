@import "../../../../colors.css";

.toolbar .lrn_widget.lrn_customfeature .lrn-customfeature-loader {
  height: 100%;
  width: 100%;
}

.toolbar .lrn .lrn_btn {
  color: var(--orange);
  background-color: transparent;
  padding-top: 8px;
  font-size: 20px;
  height: 100%;
  width: 100%;
  box-shadow: none;
}

.toolbar .lrn .lrn_btn {
  color: var(--orange);
  background-color: transparent;
  padding-top: 8px;
  font-size: 20px;
  height: 100%;
  width: 100%;
  box-shadow: none;
}

.toolbar .lrn .lrn_btn:hover,
.toolbar .lrn .lrn_btn.lrn_hover,
.toolbar .lrn .lrn_btn:active,
.toolbar .lrn .lrn_btn.lrn_active,
.toolbar .lrn .lrn_btn:focus {
  color: var(--orange);
  background-color: transparent;
}

.toolbar .lrn .lrn_btn:active,
.toolbar .lrn .lrn_btn.lrn_active,
.toolbar .lrn .lrn_btn:focus {
  box-shadow: none;
}

.toolbar .lrn .lrn_btn:active,
.toolbar .lrn .lrn_btn.lrn_active {
  outline: auto 2px var(--blue);
}

.toolbar .lrn .lrn_btn:before,
.toolbar .lrn_feature.lrn_imagetool .lrn_imagetool-toggle:before {
  margin: 0;
  padding: 0;
}

.toolbar .learnosity-feature .lrn_spinner {
  display: flex;
  align-items: center;
}
