.labelContainer {
  display: flex;
  width: 96px;
  grid-area: 1 / 1 / 2 / 2;
}
.label {
  display: flex;
  justify-content: center;
  font-family: 'WorkSans-SemiBold';
  font-size: 14px;
  height: fit-content;
  width: 100%;
  padding: 4px 12px;
  border-radius: 4px;
  text-transform: uppercase;
  color: var(--white);
}