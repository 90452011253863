@import "../../colors.css";

.studentDataWrapper {
  padding: 20px 30px;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mathStudentDataHeader {
  margin: 10px 0px 40px 0px;
}

.reveal {
  background: var(--white);
  box-sizing: border-box;
  box-shadow: 0 4px 20px -2px var(--light-gray1);
  position: absolute;
  top: 106px;
  width: 800px;
  padding: 10px;
  overflow: hidden;
  z-index: 1;
  display: none;
}

.showReveal {
  display: block;
}

.revealBtn {
  background: none;
  border: 0;
  cursor: pointer;
  color: var(--blue);
  font-size: 24px;
  font-family: 'AvenirNext-Bold';
  padding: 0;
}

.trademarkText {
  font-size: 12px;
  text-align: right;
  color: var(--light-gray1);
  margin-bottom: 0;
}

.label {
  text-transform: uppercase;
  margin-bottom: 10px;
}

.datePickerContainer {
  margin-left: auto;
}

.buttonContainer {
  display: flex;
}

@media print {
  .hideForPrint {
    display: none;
  }
}