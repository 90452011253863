.menuSection {
  background-color: #263237;
  padding-bottom: 20px;

  .menuSectionHeader {
    border-bottom: 1px solid #5a676d;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 20px;
  }

  .menuSectionActionButtons {
    display: flex;

    a,
    button,
    [class*='icon-']::before {
      color: #cfd8dc;
      fill: #cfd8dc;
      margin-left: 6px;
      line-height: initial;
      text-decoration: none;
    }
  }

  .menuTitle {
    color: #cfd8dc;
    font-family: 'WorkSans-Medium';
    font-size: 14px;
    text-transform: uppercase;
  }

  .menuItem {
    &.selected,
    &[class*='selected'] {
      a,
      button {
        background-color: #626d73;
        color: #fff;
        font-family: 'WorkSans-Bold';
      }
    }

    &:hover:not(.selected),
    &:focus:not(.selected),
    &:hover:not([class*='selected']),
    &:focus:not([class*='selected']) {
      background-color: #607d8b;
      outline: none;

      a:not([class*='disabled']),
      button:not([class*='disabled']),
      svg,
      img,
      [class*='icon-']::before {
        color: #fff;
        fill: #fff;
      }
    }

    // css grid doesn't work directly on a button in Safari
    a,
    button .buttonContentWrapper {
      align-items: center;
      display: grid;
      grid-template-columns: auto auto 1fr;
    }

    a,
    button {
      background-color: transparent;
      border: 0;
      box-sizing: border-box;
      color: #cfd8dc;
      cursor: pointer;
      font-family: 'WorkSans-Medium';
      font-weight: normal;
      font-size: 15px;
      padding: 10px;
      position: relative;
      overflow-wrap: break-word;
      width: 100%;
      min-height: 40px;
      text-align: left;
      text-decoration: none;

      &[class*='selected'] {
        background-color: #626d73;
        color: #fff;
        font-family: 'WorkSans-Bold';
      }

      &[class*='disabled'] {
        cursor: not-allowed;
        opacity: 0.4;
      }

      svg,
      img,
      [class*='icon-'] {
        justify-self: flex-end;
      }

      [class*='icon-'] {
        &::before {
          color: #cfd8dc;
          line-height: initial;
        }
      }
    }
  }

  .menuItemSection {
    svg,
    img {
      margin-right: 20px;
      width: 25px;
      height: 25px;
    }

    [class*='icon'],
    svg {
      vertical-align: middle;
    }

    [class*='avatar'],
    [class*='icon'] {
      margin-right: 20px;
    }

    [class*='progress'] {
      padding-top: 18px;
      padding-bottom: 32px;
    }

    [class*='hasNotifications'] {
      padding-right: 40px;
    }

    [class*='notificationCount'] {
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 !important;
    }
  }

  .showMore {
    button {
      font-size: 13px;
    }

    svg {
      margin-right: 10px;
      width: 14px;
      height: 14px;
    }
  }

  &.light {
    background-color: transparent;

    .menuSectionActionButtons {
      a,
      button,
      [class*='icon-']::before {
        color: #253238;
        fill: #253238;

        &:hover:not([class*='disabled']),
        &:focus:not([class*='disabled']) {
          color: #0077d1;
          fill: #0077d1;
        }
      }
      a,
      button {
        &:hover:not([class*='disabled']),
        &:focus:not([class*='disabled']) {
          color: #0077d1;
          fill: #0077d1;
        }
      }
    }

    .menuTitle {
      color: #253238;
      font-family: 'WorkSans-SemiBold';
    }

    a,
    button {
      &[class*='disabled'] {
        color: #808080;
        cursor: not-allowed;
        
        &:hover, 
        &:focus {
          color: #808080;
        }
      }
    }

    .menuItem {
      a,
      button,
      img,
      [class*='icon-']::before {
        color: #253238;
        fill: #253238;
        stroke: #253238;

        &[class*='selected'] {
          background-color: #626d73;
          color: #fff;
          font-family: 'WorkSans-Bold';
        }
      }

      &:hover:not(.selected),
      &:focus:not(.selected),
      &:hover:not([class*='selected']),
      &:focus:not([class*='selected']) {
        background-color: transparent;
        outline: none;

        a:not([class*='disabled']),
        button:not([class*='disabled']),
        svg,
        img,
        [class*='icon-']::before {
          color: #016cc6;
          fill: #0077d1;
          stroke: #0077d1;
        }
      }
    }
  }

  &.noTitle {
    padding: 0;
  }
}
