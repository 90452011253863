/* flexbox stuff */
.flex {
  display: flex;
  align-items: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.alignEnd {
  align-self: flex-end;
}

.alignStart {
  align-self: flex-start;
}

.alignCenter {
  align-self: center;
}

.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexWrap {
  flex-wrap: wrap;
}

.noShrink {
  flex-shrink: 0;
}

.flexGrow {
  flex-grow: 1;
}

.flexFullWidth {
  flex: 1;
}

/* generic layout stuff */

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fullWidth {
  width: 100%;
}

.textAlignRight {
  text-align: right !important;
}

.textAlignCenter {
  text-align: center !important;
}

.verticalAlignTop {
  vertical-align: top;
}

.verticalAlignMiddle {
  vertical-align: middle;
}

.clearBoth {
  clear: both;
}