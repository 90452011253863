@import "../colors.css";

.MuiDialogContent-root {
  border-bottom: solid 1px var(--light-gray1);
  font-family: var(--font-avenir-med);
  font-size: 18px;
  color: var(--dark-blue);
}

.MuiDialogContent-root .MuiTypography-body1 {
  font-family: var(--font-avenir-med) !important;
}

.MuiDialogTitle-root,
.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--dark-blue) !important;
}

.MuiCheckbox-colorPrimary.Mui-disabled {
  color: var(--medium-gray) !important;
}

.MuiDialogActions-root .MuiButton-root {
  font-family: var(--font-avenir-med);
  font-size: 16px;
  color: var(--dark-blue);
  padding: 8px 6px;
}

.MuiPickersDay-day {
  color: rgba(0, 0, 0, 0.87) !important;
}

.MuiPickersDay-daySelected {
  color: #fff !important;
  background-color: #0077D1 !important;
}

.MuiPickersDay-dayDisabled {
  color: rgba(0, 0, 0, 0.38) !important;
}